// @ts-nocheck
import { getFaqsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetFaqsModel } from "../../Models";

const url = `${process.env.REACT_APP_CMS_API_URL}/Api/Faq/BelbimIstanbulFaqDetails`;
const getFaqsDetail = async ({ languageId, Id }: any): any => {
  debugger;
  return (
    await axiosHelper({
      method: "post",
      url,
      data: { LanguageId: languageId, Id },
      parameters: { languageId, Id },
    })
  ).data;
};

export default getFaqsDetail;
