import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { History } from "history";
import global from "./global/slices";
import sliders from "./getSliders/slices";
import announcements from "./getAnnouncements/slices";
import news from "./getNews/slices";
import faqs from "./getFaqs/slices";
import customerInformation from "./checkCustomerInformationDemo/slices";
import collaborations from "./getCollaborations/slices";
import istanbulkartFindContent from "./getIstanbulkartFindContent/slices";
import ourRepresentatives from "./getOurRepresentatives/slices";
import cityList from "./getCityList/slices";
import townList from "./getTownList/slices";
import getActivityReports from "./getActivityReports/slices";
import security from "./getSecurity/slices";
import getIstanbulkartLegalTextPage from "./getIstanbulkartLegalTextPage/slices";
import getProductsAndServices from "./getProductsAndServices/slices";
import getBelbimCompanyJourney from "./getBelbimCompanyJourney/slices";
import faqsDetail from "./getFaqsDetail/slices";

// Add Reducers Here
const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    global,
    sliders,
    security,
    faqsDetail,
    announcements,
    news,
    faqs,
    customerInformation,
    collaborations,
    istanbulkartFindContent,
    ourRepresentatives,
    cityList,
    townList,
    getActivityReports,
    getIstanbulkartLegalTextPage,
    getProductsAndServices,
    getBelbimCompanyJourney,
  });

export default rootReducer;
