import { Box, Grid, Typography } from "@material-ui/core";
import Images from "Assets/Images";
import {
  FacebookGrayIcon,
  LinkGrayIcon,
  LinkedInGrayIcon,
  Quotes,
  Rectangle386,
  XGrayIcon,
} from "Icons";
import { makeLink } from "Utils";
import classNames from "classnames";
import { FC, memo } from "react";
import "./PersonalDetail.scss";

type PersonalDetailProps = {
  data: any;
  isOrganization?: boolean;
  onClickBack?: Function;
};
const PersonalDetail: FC<PersonalDetailProps> = memo(
  ({ data, isOrganization = false, onClickBack }) => {
    return (
      <div id="personalDetail" className="pageContainer">
        {isOrganization && (
          <img
            className="personalDetail__backButton"
            height="100%"
            src={Images.Back}
            onClick={() => {
              onClickBack && onClickBack();
            }}
          />
        )}

        <div className="personalDetail__container">
          <div className="personalDetail__visible">
            <Rectangle386 />
          </div>

          <Typography variant="h5" align="left">
            <Box fontWeight={700} className="personalDetail__title">
              {data?.title}
            </Box>
          </Typography>
        </div>
        <Typography variant="h2" align="left" paragraph>
          <Box fontWeight={700} className="personalDetail__name">
            {data?.name}
          </Box>
        </Typography>
        <div style={{ height: 20 }} />
        <div className="personalDetail__container2">
          <Grid item xs={12} lg={8}>
            {!isOrganization && (
              <div className="personalDetail__visible">
                <Quotes />
              </div>
            )}

            <p
              className={classNames({
                personalDetail__vision: isOrganization ? false : true,
                personalDetail__content2: isOrganization ? true : false,
              })}
              dangerouslySetInnerHTML={{
                __html: makeLink(data?.content1),
              }}
            ></p>
            {!isOrganization && (
              <div className="personalDetail__bottomQuotes">
                <Quotes />
              </div>
            )}

            {data?.content2 && (
              <p
                style={{ marginTop: isOrganization ? 0 : 55 }}
                className="personalDetail__content2"
                dangerouslySetInnerHTML={{
                  __html: makeLink(data?.content2),
                }}
              ></p>
            )}
            {data?.content3 && (
              <p
                className="personalDetail__content2"
                dangerouslySetInnerHTML={{
                  __html: makeLink(data?.content3),
                }}
              ></p>
            )}
            {data?.content4 && (
              <p
                className="personalDetail__content2"
                dangerouslySetInnerHTML={{
                  __html: makeLink(data?.content4),
                }}
              ></p>
            )}
            {data?.content5 && (
              <p
                className="personalDetail__content2"
                dangerouslySetInnerHTML={{
                  __html: makeLink(data?.content5),
                }}
              ></p>
            )}
            {data?.content5 && (
              <p
                className="personalDetail__content2"
                dangerouslySetInnerHTML={{
                  __html: makeLink(data?.content6),
                }}
              ></p>
            )}
            {data?.content5 && (
              <p
                className="personalDetail__content2"
                dangerouslySetInnerHTML={{
                  __html: makeLink(data?.content7),
                }}
              ></p>
            )}
          </Grid>
          {!!data?.imgPath ? (
            <Grid item>
              <img
                className="personalDetail__container2__image"
                height="510px"
                width="421px"
                src={data?.imgPath}
                alt={data?.title}
              />
            </Grid>
          ) : null}
        </div>

        <div className="personalDetail__bottomContainer">
          {/* {!isOrganization && (
            <div className="personalDetail__socialmedia">
              <div
                className="personalDetail__icon"
                onClick={() => {
                  let newWindow: any = window.open();
                  newWindow.location = "https://x.com/belbimibb";
                }}
              >
                <XGrayIcon />
              </div>
              <div
                className="personalDetail__icon"
                onClick={() => {
                  let newWindow: any = window.open();
                  newWindow.location = "https://www.facebook.com/belbim";
                }}
              >
                <FacebookGrayIcon />
              </div>
              <div
                className="personalDetail__icon"
                onClick={() => {
                  let newWindow: any = window.open();
                  newWindow.location =
                    "https://www.linkedin.com/company/belbimas/";
                }}
              >
                <LinkedInGrayIcon />
              </div>
              <div
                className="personalDetail__icon"
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      "https://belbim-istanbul-website.web.app/institutional/"
                    )
                    .then(() => {
                      alert("Bağlantı kopyalandı.");
                    })
                    .catch(() => {
                      alert("Bağlantı kopyalanırken bir hata oluştu.");
                    });
                }}
              >
                <LinkGrayIcon />
              </div>
            </div>
          )} */}

          <div>
            <Typography variant="h5" align="left">
              <Box
                fontWeight={700}
                className="personalDetail__bottomTitle"
                dangerouslySetInnerHTML={{ __html: data?.bottomTitle }}
              ></Box>
            </Typography>
            <div style={{ height: 12 }} />
            {data?.bottomTitle2?.includes("#") ? (
              data?.bottomTitle2.split("#").map((item: any) => {
                return (
                  <Typography variant="h5" align="left">
                    <Box
                      fontWeight={700}
                      className="personalDetail__bottomTitle"
                      dangerouslySetInnerHTML={{ __html: item }}
                    ></Box>
                  </Typography>
                );
              })
            ) : (
              <Typography variant="h5" align="left">
                <Box
                  fontWeight={700}
                  className="personalDetail__bottomTitle"
                  dangerouslySetInnerHTML={{ __html: data?.bottomTitle2 }}
                ></Box>
              </Typography>
            )}
          </div>
        </div>
      </div>
    );
  }
);
export default PersonalDetail;
