import { all, spawn } from "redux-saga/effects";
//Sagas
import globalSaga from "./global/sagas";
import slidersSaga from "./getSliders/sagas";
import announcementsSaga from "./getAnnouncements/sagas";
import newsSaga from "./getNews/sagas";
import faqsSaga from "./getFaqs/sagas";
import checkCustomerInfromationSaga from "./checkCustomerInformationDemo/sagas";
import collaborationsSaga from "./getCollaborations/sagas";
import istanbulkartFindContentSaga from "./getIstanbulkartFindContent/sagas";
import ourRepresentativesSaga from "./getOurRepresentatives/sagas";
import citiesSaga from "./getCityList/sagas";
import townListSaga from "./getTownList/sagas";
import getActivityReportsSaga from "./getActivityReports/sagas";
import securitySaga from "./getSecurity/sagas";
import GetIstanbulkartLegalTextPage from "./getIstanbulkartLegalTextPage/sagas";
import getBelbimCompanyJourney from "./getBelbimCompanyJourney/sagas";
import GetProductsAndServices from "./getProductsAndServices/sagas";
import faqDetailSaga from "./getFaqsDetail/sagas";

export default function* rootSaga() {
  yield all([
    spawn(globalSaga),
    spawn(slidersSaga),
    spawn(announcementsSaga),
    spawn(faqDetailSaga),
    spawn(securitySaga),
    spawn(newsSaga),
    spawn(faqsSaga),
    spawn(checkCustomerInfromationSaga),
    spawn(collaborationsSaga),
    spawn(istanbulkartFindContentSaga),
    spawn(ourRepresentativesSaga),
    spawn(citiesSaga),
    spawn(townListSaga),
    spawn(getActivityReportsSaga),
    spawn(GetIstanbulkartLegalTextPage),
    spawn(GetProductsAndServices),
    spawn(getBelbimCompanyJourney),
  ]);
}
