import React, { useEffect, useState } from "react";
import "./Institutional.scss";
import { TabBox } from "./TabBox";
import PersonalDetail from "./PersonalDetail/PersonalDetail";
import AboutAs from "./AboutAs/AboutAs";
import Reports from "./Reports/Reports";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useWindowDimensions, useLocalization } from "Hooks";
import { Box, Typography } from "@material-ui/core";
import { RightArrowGray } from "Icons";
import { useSelector } from "react-redux";
import { selectLocalizationLoaded } from "Store/global";

export interface IInstitutionalProps { }

const Institutional: React.FunctionComponent<IInstitutionalProps> = (props) => {
  const isLoaded = useSelector(selectLocalizationLoaded);
  const location = useLocation();
  const localization = useLocalization();
  const [params] = useSearchParams();
  const { id, tab, subTab } = location?.state || {};
  const _id: any = id ?? 0;
  const _tab: string = tab ?? "1";
  const _subTab: string = subTab ?? "1";
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      title: localization?.institutional_presidents_message_title,
      name: localization?.institutional_presidents_message_header,
      imgPath: localization.institutional_presidents_message_image,
      content1: localization?.institutional_presidents_message_subheader,
      content2: localization?.institutional_presidents_message_desc_paragraph_1,
      content3: localization?.institutional_presidents_message_desc_paragraph_2,
      content4: localization?.institutional_presidents_message_desc_paragraph_3,
      content5: localization?.institutional_presidents_message_desc_paragraph_4,
      content6: "",
      content7: "",
      bottomTitle:
        localization?.institutional_presidents_message_signature_line_1,
      bottomTitle2:
        localization?.institutional_presidents_message_signature_line_2,
    },
    {
      id: 2,
      title: localization?.institutional_general_manager_title,
      name: localization?.institutional_general_manager_header,
      imgPath: localization.institutional_general_manager_image,
      content1: localization?.institutional_general_manager_subheader,
      content2: localization?.institutional_general_manager_desc_paragraph_1,
      content3: localization?.institutional_general_manager_desc_paragraph_2,
      content4: localization?.institutional_general_manager_desc_paragraph_3,
      content5: localization?.institutional_general_manager_desc_paragraph_4,
      content6: localization?.institutional_general_manager_desc_paragraph_5,
      content7: localization?.institutional_general_manager_desc_paragraph_6,
      bottomTitle: localization?.institutional_general_manager_signature_line_1,
      bottomTitle2:
        localization?.institutional_general_manager_signature_line_2,
    },
  ];

  const [selectedData, setSelectedData] = useState<any>(null);
  const _title =
    _id === 0
      ? localization?.institutional_menu_presidents_message
      : _id === 1
        ? localization?.institutional_menu_general_manager
        : _id === 2
          ? localization?.institutional_menu_about_us
          : _id === 3
            ? localization?.institutional_menu_reports
            : "";

  const page = params.get("page");
  const isPresident =
    !page || ["president-message", "general-manager"].includes(page);
  const aboutUs = !!page && page === "about-us";
  const reports = !!page && page === "reports";

  useEffect(() => {
    if (isLoaded) {
      if (!page || page === "president-message") {
        setSelectedData(data[0]);
        return;
      }
      if (page === "general-manager") {
        setSelectedData(data[1]);
        return;
      }
    }
  }, [isLoaded, page]);

  return (
    <div id="institutional" className="pageContainer">
      {!isLoaded && <div className="institutional__loading"></div>}
      <>
        {useWindowDimensions().width > 959 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 50,
              }}
            >
              <TabBox
                text={localization?.institutional_menu_presidents_message}
                isActive={!page || page === "president-message"}
                onPress={() => {
                  navigate("/institutional?page=president-message");
                }}
                photoPath={localization.institutional_presidents_message_image_thumbnail}
              />
              <TabBox
                text={localization?.institutional_menu_general_manager}
                isActive={page === "general-manager"}
                onPress={() => {
                  navigate("/institutional?page=general-manager");
                }}
                photoPath={localization.institutional_general_manager_image_thumbnail}
              />
              <TabBox
                text={localization?.institutional_menu_about_us}
                isActive={aboutUs}
                onPress={() => {
                  navigate("/institutional?page=about-us");
                }}
              />
              <TabBox
                text={localization?.institutional_menu_reports}
                isActive={reports}
                onPress={() => {
                  navigate("/institutional?page=reports");
                }}
              />
            </div>
          </>
        ) : (
          <div>
            <div
              style={{
                width: "100%",
                height: 2,
                backgroundColor: "#F2F4F6",
                marginBottom: 17,
              }}
            />
            <Typography
              className="processingPoints__processingPointsMobile__title"
              variant="h6"
              align="left"
            >
              <Box
                fontSize={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                {localization?.homepage_menu_institutional} <RightArrowGray />{" "}
                {_title}
              </Box>
            </Typography>
            <Typography variant="h5" align="center">
              <Box
                fontWeight={700}
                className="processingPoints__processingPointsMobile__header"
              >
                {_title}
              </Box>
            </Typography>
          </div>
        )}
        {isPresident && <PersonalDetail data={selectedData} />}
        {aboutUs && <AboutAs activeTab={_subTab} />}
        {reports && <Reports />}
      </>
    </div>
  );
};
export default Institutional;
