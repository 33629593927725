import { useLocalization, useWindowDimensions } from "Hooks";
import { CompanyJourneyList } from "Models/CompanyJourneyModel";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { A11y, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "./TimeLine.scss";

type Timelineprops = {
  events?: CompanyJourneyList[];
  text?: string;
  onPress?: Function;
  isActive?: boolean;
  photoPath?: string;
  selectedIndex: number;
};
const TimeLine: FunctionComponent<Timelineprops> = ({
  events = [],
  onPress = () => {},
  selectedIndex,
}) => {
  const reversedIndex = (index: number) => events.length - 1 - index; // Map to reversed index

  return (
    <div id="timeLine" className="pageContainer">
      <Swiper
        modules={[Pagination, A11y]}
        slidesPerView={"auto"}
        pagination={false}
        navigation={false}
        spaceBetween={useWindowDimensions().width > 10000 ? 150 : 15}
        style={{
          alignItems: "flex-start",
          padding: "20px 0",
          borderBottom: "2px dashed #666666",
        }}
      >
        {events.map((item: any, index: number) => (
          <SwiperSlide style={{ width: "fit-content" }} key={index}>
            <div
              onClick={() => {
                onPress(reversedIndex(index)); // Pass reversed index back to parent
              }}
            >
              <h4
                className="timeLine__year"
                style={{
                  fontSize: selectedIndex === reversedIndex(index) ? 42 : 21,
                  color:
                    selectedIndex === reversedIndex(index)
                      ? "#43A9D6"
                      : "#004469",
                  marginTop:
                    selectedIndex === reversedIndex(index) ? -15 : "inherit",
                }}
              >
                {item?.Year}
              </h4>
              {selectedIndex === reversedIndex(index) && (
                <span
                  style={{
                    position: "absolute",
                    bottom: "2px",
                    zIndex: 555,
                  }}
                />
              )}
              <div
                className="timeLine__circle"
                style={{
                  marginTop: selectedIndex === reversedIndex(index) ? 15 : 0,
                  marginLeft: selectedIndex === reversedIndex(index) ? 50 : 20,
                  backgroundColor:
                    selectedIndex === reversedIndex(index)
                      ? "#43A9D6"
                      : "#004469",
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TimeLine;
