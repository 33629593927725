import { configureStore } from "@reduxjs/toolkit";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { CustomerInformationState } from "./checkCustomerInformationDemo/types";
import { GetActivityReportsState } from "./getActivityReports";
import { AnnouncementsState } from "./getAnnouncements/types";
import { CityListState } from "./getCityList";
import { CollaborationsState } from "./getCollaborations/types";
import { FaqsState } from "./getFaqs/types";
import { IstanbulkartFindContentState } from "./getIstanbulkartFindContent";
import { legalTextState } from "./getIstanbulkartLegalTextPage";
import { NewsState } from "./getNews/types";
import { OurRepresentativesState } from "./getOurRepresentatives";
import { SlidersState } from "./getSliders/types";
import { TownListState } from "./getTownList";
import { GlobalState } from "./global/types";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { ProductAndServices } from "./getProductsAndServices";
import { CompanyJourneyState } from "./getBelbimCompanyJourney";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleware({
    thunk: false,
    immutableCheck: false,
    serializableCheck: false,
  })
    .concat(sagaMiddleware)
    .concat(routerMiddleware(history));

const store = configureStore({
  reducer: rootReducer(history),
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

export interface RootState {
  global: GlobalState;
  sliders: SlidersState;
  announcements: AnnouncementsState;
  faqsDetail: any;
  news: NewsState;
  faqs: FaqsState;
  customerInformation: CustomerInformationState;
  collaborations: CollaborationsState;
  istanbulkartFindContent: IstanbulkartFindContentState;
  ourRepresentatives: OurRepresentativesState;
  cityList: CityListState;
  townList: TownListState;
  getActivityReports: GetActivityReportsState;
  getIstanbulkartLegalTextPage: legalTextState;
  getBelbimCompanyJourney: CompanyJourneyState;
  getProductsAndServices: ProductAndServices;
}

export default store;
