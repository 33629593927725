import { Box, Typography } from "@material-ui/core";
import Images from "Assets/Images";
import { useLocalization, useWindowDimensions } from "Hooks";
import SectionTab from "Pages/ProcessingPoints/SectionTab";
import { RootState } from "Store";
import { CompanyJourneyRequest } from "Store/getBelbimCompanyJourney";
import { makeLink } from "Utils";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwiperCore, { A11y, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { OrganizationChart } from "../OrganizationChart";
import { PersonalDetail } from "../PersonalDetail";
import TimeLine from "../TimeLine/TimeLine";
import "./AboutAs.scss";
export interface IInstitutionalProps {
  activeTab: string;
}

const AboutAs: React.FunctionComponent<IInstitutionalProps> = (props) => {
  const activeTab = props.activeTab;
  const localization = useLocalization();
  const [isDetail, setIsDetail] = useState(false);
  const [data, setData] = useState({});
  const [hakkinda, setHakkinda] = useState(activeTab === "1");
  const [selectedTimeLineIndex, setSelectedTimeLineIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CompanyJourneyRequest());
  }, []);

  const [vizyonmisyon, setVizyonMisyon] = useState(activeTab === "2");
  const CompanyJourneyList = useSelector(
    (store: RootState) => store.getBelbimCompanyJourney
  );

  const [organizasyonSemasi, setOrganizasyonSemasi] = useState(
    activeTab === "3"
  );

  const slidesPerView = useWindowDimensions().width > 900 ? 2 : 1;
  const swiperRef = useRef<SwiperCore | null>(null);

  const reversedJourneyList = [...CompanyJourneyList?.CompanyJourneyList]?.sort(
    (a: any, b: any) => b.Year - a.Year
  );

  // Handle timeline click
  const handleTimelineClick = (clickedIndex: number) => {
    const swiperIndex = reversedJourneyList.length - 1 - clickedIndex; // Map timeline index to reversed Swiper index
    if (swiperRef.current) {
      swiperRef.current.slideTo(swiperIndex, 500); // Navigate to the correct Swiper slide
    }
    setSelectedTimeLineIndex(clickedIndex); // Update the timeline selection
  };

  // Sync timeline index when Swiper changes
  const handleSlideChange = (swiper: SwiperCore) => {
    const timelineIndex = reversedJourneyList.length - 1 - swiper.activeIndex; // Map Swiper index to timeline index
    setSelectedTimeLineIndex(timelineIndex);
  };
  useEffect(() => {
    setSelectedTimeLineIndex(0);
  }, []);
  return (
    <div id="aboutas" className="pageContainer">
      <div style={{ display: "flex", flexDirection: "row", marginTop: 33 }}>
        <SectionTab
          text={localization?.institutional_about_us_tab_about}
          isActive={hakkinda}
          onPress={() => {
            setHakkinda(true);
            setVizyonMisyon(false);
            setOrganizasyonSemasi(false);
          }}
        />
        <SectionTab
          text={localization?.institutional_about_us_tab_vision_and_mission}
          isActive={vizyonmisyon}
          onPress={() => {
            setHakkinda(false);
            setVizyonMisyon(true);
            setOrganizasyonSemasi(false);
          }}
        />
        <SectionTab
          text={localization?.institutional_about_us_tab_organization_chart}
          isActive={organizasyonSemasi}
          onPress={() => {
            setHakkinda(false);
            setVizyonMisyon(false);
            setOrganizasyonSemasi(true);
            setIsDetail(false);
          }}
        />
      </div>
      {hakkinda ? (
        <div>
          <Typography variant="h2" align="left">
            <Box fontWeight={700} className="aboutas__title">
              {localization?.institutional_about_us_tab_about_header}
            </Box>
          </Typography>
          <p
            className="aboutas__content"
            dangerouslySetInnerHTML={{
              __html: makeLink(
                localization?.institutional_about_us_tab_about_desc_paragraph_1
              ),
            }}
          ></p>
          <Typography variant="h5" align="center">
            <Box fontWeight={700} className="aboutas__map">
              {localization?.institutional_company_journey_header}
            </Box>
          </Typography>
          <Swiper
            modules={[Pagination, Navigation, A11y]}
            slidesPerView={slidesPerView}
            navigation={true}
            onSlideChange={handleSlideChange} // Sync timeline index when Swiper changes
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
              console.log(swiperRef.current);
              swiperRef.current.activeIndex = 16;
            }}
            className="company_journey__slider"
          >
            {CompanyJourneyList.CompanyJourneyList.map(
              ({ Title, Year, Description }: any, index: any) => (
                <SwiperSlide className="aboutas__item" key={index}>
                  <div>
                    <h4 className="aboutas__item__title">{Year}</h4>
                    <h6 className="aboutas__item__subTitle">{Title}</h6>
                    <p
                      className="aboutas__item__content"
                      dangerouslySetInnerHTML={{
                        __html: makeLink(Description),
                      }}
                    />
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
          <div className="aboutas__timeline">
            <TimeLine
              events={CompanyJourneyList?.CompanyJourneyList} // Past-to-current order
              selectedIndex={selectedTimeLineIndex}
              onPress={handleTimelineClick} // Sync Swiper on timeline click
            />
          </div>
        </div>
      ) : vizyonmisyon ? (
        <div>{/* Vision & Mission Content */}</div>
      ) : !isDetail && organizasyonSemasi ? (
        <OrganizationChart
          detailData={{}}
          setDetailData={(val: any) => {
            setIsDetail(true);
            setData(val);
            window.scrollTo({ top: 0 });
          }}
        />
      ) : (
        isDetail && (
          <PersonalDetail
            data={data}
            isOrganization={true}
            onClickBack={() => {
              setIsDetail(false);
              setHakkinda(false);
              setVizyonMisyon(false);
              setOrganizasyonSemasi(true);
            }}
          />
        )
      )}
    </div>
  );
};

export default AboutAs;
