import { FC, memo, useMemo } from "react";
import { useLocalization } from "Hooks";
import Items, { ItemsType } from "./Items/Items";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInGrayIcon,
  LocationBlue,
  PhoneCircle,
  XIcon,
} from "Icons";
import { isMobile } from "react-device-detect";
import { Box, Grid, Typography } from "@material-ui/core";
import "./Contact.scss";
import YoutubeIcon from "Icons/YoutubeIcon";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { contactCmsData } from "Store/global";
import { get } from "lodash";

const SOCIAL_LINKS = {
  INSTAGRAM: "https://www.instagram.com/istanbulkart.istanbul/",
  X: "https://x.com/istanbulkart",
  FACEBOOK: "https://www.facebook.com/istanbulkart/",
  YOUTUBE: "https://www.youtube.com/@Belbim",
  LINKEDIN: "https://www.linkedin.com/company/belbimas",
};

const Contact: FC = memo((props) => {
  const localization = useLocalization();
  const cmsData = useSelector(contactCmsData);
  const call153 = () => {
    window.open("tel:153");
  };

  const call = () => {
    window.open("tel:0212 468 00 00");
  };

  const redirectToIstanbulKart = () => {
    window.open("https://istanbulkart.istanbul/applicationCenters", "_blank");
  };

  const data: ItemsType[] = [
    {
      title: localization?.contact_individual_customer_line_no,
      subTitle: localization?.contact_individual_customer_line_desc,
      Icon: PhoneCircle,
      func: isMobile ? call153 : undefined,
      itemID: "call153BtnTest",
      showArrow: false,
    },
    {
      title: localization?.contact_institutional_customer_line_no,
      subTitle: localization?.contact_institutional_customer_line_desc,
      Icon: PhoneCircle,
      func: isMobile ? call : undefined,
      itemID: "writeUsBtnTest",
      showArrow: false,
    },
    {
      title: localization?.contact_application_center_header,
      subTitle: localization?.contact_application_center_desc,
      Icon: LocationBlue,
      func: redirectToIstanbulKart,
      itemID: "ibmPointsBtnTest",
      showArrow: true,
    },
  ];
  return (
    <Grid
      container
      justifyContent="center"
      style={{ maxWidth: "1160px", margin: "0 auto" }}
    >
      <Grid lg={6} xs={12}>
        <Box className="contact">
          <Typography
            variant="h5"
            component="h5"
            align="center"
            className="contact__title"
          >
            {localization.contact_page_header}
          </Typography>
          <Box className="contact__items">
            {data.map((item: ItemsType) => (
              <Items key={item.itemID} {...item} />
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Grid container className="info__container">
          <Grid item xs={12} className="infoData">
            {typeof cmsData.contact_belbim_office_address === "string" &&
              parse(
                cmsData?.contact_belbim_office_address.replace(/\n/g, "<br/>")
              )}
          </Grid>
          <Grid
            item
            xs={12}
            className="infoData"
            style={{ marginTop: "1.4rem" }}
          >
            {typeof cmsData.contact_controller_address === "string" &&
              parse(
                cmsData?.contact_controller_address.replace(/\n/g, "<br/>")
              )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1.4rem" }}>
            <Typography variant="h5" component="h5" className="contact__title">
              {localization.contact_social_media_desc}
            </Typography>
            <Box className="contact__icons">
              <a target="_blank" href={SOCIAL_LINKS.FACEBOOK}>
                <FacebookIcon />
              </a>
              <a target="_blank" href={SOCIAL_LINKS.X}>
                <XIcon />
              </a>
              <a target="_blank" href={SOCIAL_LINKS.INSTAGRAM}>
                <InstagramIcon />
              </a>
              <a target="_blank" href={SOCIAL_LINKS.YOUTUBE}>
                <YoutubeIcon />
              </a>
              <a
                target="_blank"
                className="linkedinSvg"
                href={SOCIAL_LINKS.LINKEDIN}
              >
                <LinkedInGrayIcon color="#0288D1" />
              </a>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Contact;
