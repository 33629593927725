import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, A11y } from "swiper";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "./Welcome.scss";
import Grid from "@material-ui/core/Grid";
import { AnnouncementCard, NewBox, Button } from "Components";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "Store";
import { useWindowDimensions } from "Hooks";
import { routePath } from "Navigator/routes";
import { formatDate } from "Utils";
import { AnnouncementPopup } from "Components/AnnouncementPopup";
import { useLocalization } from "Hooks";
import classnames from "classnames";
import { isTablet, isMobile } from 'react-device-detect';

export interface IWelcomeProps {}

const Welcome: React.FunctionComponent<IWelcomeProps> = (props) => {
  const navigate = useNavigate();
  const localization = useLocalization();
  const { WebSliderList } = useSelector((state: RootState) => state.sliders);
  const { NewsList } = useSelector((state: RootState) => state.news);
  const { width } = useWindowDimensions();
  const arrayToSortNews = NewsList || [];
  const sortedNewsList = arrayToSortNews
    .slice()
    .sort(function (a: any, b: any) {
      return (
        new Date(a.RelaseDate).valueOf() - new Date(b.RelaseDate).valueOf()
      );
    });
  const { AnnouncementList } = useSelector(
    (state: RootState) => state.announcements
  );
  const arrayToSort = AnnouncementList || [];
  const sortedAnnouncementList = arrayToSort
    .slice()
    .sort(function (a: any, b: any) {
      return new Date(a.Date).valueOf() - new Date(b.Date).valueOf();
    });

  const announcement = {
    header: "Announcement Header",
    description: "Announcement Description",
    image: "path/to/image",
    link: "https://www.example.com",
    time: 5, // in seconds
  };

  const [showPopup, setShowPopup] = useState(
    Boolean(
      announcement.header ||
        announcement.description ||
        announcement.image ||
        announcement.link
    )
  );

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
  return (
    <div id="homepage">
      {showPopup && (
        <AnnouncementPopup
          header={announcement.header}
          description={announcement.description}
          image={announcement.image}
          link={announcement.link}
          time={announcement.time}
          onClose={handleClosePopup}
        />
      )}

      {WebSliderList && WebSliderList?.length > 0 && (
        <Swiper
          modules={[Pagination, Navigation, A11y]}
          slidesPerView={1}
          spaceBetween={50}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
          className="homepage__topSlider"
          pagination={{ clickable: true }}
          navigation
          loop
        >
          {WebSliderList &&
            WebSliderList.map((item, i) => {
              const url = item?.RedirectUrl.includes("https://")
                ? item?.RedirectUrl
                : "https://" + item?.RedirectUrl;
              return (
                <SwiperSlide>
                  <div className="homepage__slide-item">
                    <div className="slide-content">
                      <h5>{item?.SliderHeader}</h5>
                      <p>{item?.SliderDescription}</p>
                      {item?.IsVisibleButton && item?.RedirectUrl && (
                        <>
                          <div className="homepage__topSlider__button">
                            <Button
                              text={item?.ButtonText}
                              onClick={() => {
                                window.open(url, "_blank");
                              }}
                              variant="contained"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className="slide-image"
                      onClick={() => {
                        window.open(url, "_blank");
                      }}
                    >
                      <img
                        src={isTablet ? item?.SliderImageTabletUrl : isMobile ? item?.SliderImageMobileUrl: item?.SliderImageUrl}
                        alt={item?.SliderHeader}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      )}
      <div>
        <h2 className="homepage__announcements__title">
          {localization?.announcements_page_header}
        </h2>
      </div>
      <div
        className="homepage__announcements"
        style={{ marginTop: zoom < 95 ? 20 : 0 }}
      >
        <Swiper
          modules={[Pagination, Navigation, A11y]}
          slidesPerView={3}
          spaceBetween={50}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
          className="homepage__announcements__slide"
          navigation
        >
          {sortedAnnouncementList &&
            sortedAnnouncementList
              .reverse()
              .map(({ Header, Description, Id, Date }, i) => {
                return (
                  <SwiperSlide>
                    <AnnouncementCard
                      id={i}
                      title={Header}
                      subtitle={Date}
                      onClick={() => {
                        navigate({
                          pathname: routePath.announcementsDetail,
                          search: `?id=${Id}`,
                        });
                      }}
                      key={i}
                      itemID={"announcements2BtnTest"}
                    />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
      <div className="homepage__announcements__mobile pageContainer">
        <Swiper
          modules={[Pagination, Navigation, A11y]}
          slidesPerView={1}
          spaceBetween={50}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
          className="homepage__announcements__slide"
          pagination={true}
        >
          {sortedAnnouncementList &&
            sortedAnnouncementList
              .reverse()
              .map(({ Header, Description, Id, Date }: any, i: number) => {
                return (
                  <SwiperSlide style={{ paddingBottom: 40 }}>
                    <AnnouncementCard
                      id={i}
                      title={Header}
                      subtitle={Date}
                      onClick={() => {
                        navigate({
                          pathname: routePath.announcementsDetail,
                          search: `?id=${Id}`,
                        });
                      }}
                      key={i}
                      itemID={"announcements2BtnTest"}
                    />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
      <div className="homepage__news pageContainer">
        <h2 className="homepage__news__title">
          {localization?.homepage_news_header}
        </h2>
        <Grid container spacing={2}>
          {sortedNewsList &&
            sortedNewsList
              .reverse()
              .map(
                ({
                  HeadLine,
                  WebCoverPictureUrl,
                  Description,
                  RelaseDate,
                  WebcoverPictureTabletUrl = '',
                  WebCoverPicureMobileUrl = '',
                  Id,
                }) => {
                  return (
                    <Grid className="news__grid" item xs={12} md={4}>
                      <NewBox
                        title={HeadLine}
                        desc={Description}
                        date={formatDate(RelaseDate)}
                        image={
                          isTablet ? WebcoverPictureTabletUrl : isMobile ? WebCoverPicureMobileUrl :WebCoverPictureUrl}
                        Id={Id}
                      />
                    </Grid>
                  );
                }
              )}
        </Grid>
        <div className="homepage__news__button">
          <Link to={routePath.news}>
            {localization?.homepage_news_button_desc}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
