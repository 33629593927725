import { Box, Typography } from "@material-ui/core";
import Images from "Assets/Images";
import { SearchField, TabBox } from "Components";
import { useLocalization, useWindowDimensions } from "Hooks";
import { CheckMobile, ChevronDarkRightIcon, RightArrowGray } from "Icons";
import { routePath } from "Navigator/routes";
import { RootState } from "Store";
import { faqsRequest } from "Store/getFaqs";
import {
  ourRepresentativesRequest,
  resetState,
} from "Store/getOurRepresentatives";
import { fontSizes } from "Theme/Variables";
import { FaqCategories, makeLink } from "Utils";
import React, { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { OurRepresentatives } from "./OurRepresentatives";
import { OurRepresentativesList } from "./OurRepresentativesList";
import "./ProcessingPoints.scss";
import SectionTab from "./SectionTab";
/** @TODO State refactor yapılacak sonrasında HOC ile url kontrolü yapılacak */
export interface IPage1Props {}
type FaqsItemType = {
  title: string;
  id: number;
  itemID: string;
};
const ProcessingPoints: React.FunctionComponent<IPage1Props> = (props) => {
  const navigate = useNavigate();
  const { FaqList } = useSelector((state: RootState) => state.faqs);
  const { Representatives, loading } = useSelector(
    (state: RootState) => state.ourRepresentatives
  );
  const location = useLocation();
  const pathName = location.pathname;
  const locationState = location.state;
  const dispatch = useDispatch();
  const localization = useLocalization();
  const { id, tab, subTab } = location?.state || {};
  const _id: any = id ?? 0;
  const _tab: string = tab ?? "1";
  const _subTab: string = subTab ?? "1";
  const [biletmatik, setBiletmatik] = useState(_tab === "1");
  const [istanbulkartBasvuruMerkezi, setIstanbulkartBasvuruMerkezi] = useState(
    _tab === "2"
  );
  const [bireyselTemsilci, setBireyselTemsilci] = useState(_tab === "3");
  const [kurumsalTemsilci, setKurumsalTemsilci] = useState(_tab === "4");
  const [uyeIsYeri, setUyeIsYeri] = useState(_tab === "5");
  const [avantajlar, setAvantajlar] = useState(_subTab === "1");
  const [basvuru, setBasvuru] = useState(_subTab === "2");
  const [uygulamaSureci, setUygulamaSureci] = useState(_subTab === "3");
  const [temsilciliklerimiz, setTemsilciliklerimiz] = useState(_subTab === "5");
  const [sss, setSss] = useState(_subTab === "4");
  const [searchQuery, setSearchQuery] = useState("");

  const filterData = (query: any, data: any) => {
    if (!query) {
      return data;
    } else {
      let nameObject = data.filter((item: any) => {
        return (
          item.Question.toLowerCase().includes(query.toLowerCase()) && item
        );
      });
      return nameObject;
    }
  };

  const dataFiltered = filterData(searchQuery, FaqList);

  const data = [
    {
      id: 1,
      title: localization?.trading_points_menu_biletmatik,
      subTabTitle: localization?.trading_points_biletmatik_subheader1,
      content: localization?.trading_points_biletmatik_desc,
      subTabTitle2: localization?.trading_points_biletmatik_subheader2,
      content2: localization?.trading_points_biletmatik_desc_prg2,
      subTabTitle3: localization?.trading_points_biletmatik_subheader3,
      content3: localization?.trading_points_biletmatik_desc_prg3,
      imgPath: localization?.trading_points_biletmatik_media,
    },
    {
      id: 2,
      title: localization?.trading_points_istanbulkart_apply_center_header,
      subTabTitle:
        localization?.trading_points_istanbulkart_apply_center_subheader1,
      content: localization?.trading_points_istanbulkart_apply_center_desc,

      subTabTitle2:
        localization?.trading_points_istanbulkart_apply_center_subheader2,
      content2:
        localization?.trading_points_istanbulkart_apply_center_desc_prg2,
      subTabTitle3:
        localization?.trading_points_istanbulkart_apply_center_subheader3,
      content3:
        localization?.trading_points_istanbulkart_apply_center_desc_prg3,
      imgPath: localization?.trading_points_istanbulkart_apply_center_media,
    },
    {
      id: 3,
      title: localization?.trading_points_ind_rep_advantages_header,
      content: localization?.trading_points_ind_rep_advantages_desc1,
      subTabTitle: localization?.trading_points_ind_rep_advantages_subheader1,
      content2: localization?.trading_points_ind_rep_advantages_subheader2,
      content3: localization?.trading_points_ind_rep_advantages_subheader3,
      content4: localization?.trading_points_ind_rep_advantages_desc3,
      imgPath: localization?.trading_points_ind_rep_advantages_media,
    },
    {
      id: 4,
      title: localization?.trading_points_inst_rep_header,
      content: localization?.trading_points_inst_rep_desc,
      imgPath: Images.IslemNoktalari4,
    },
    {
      id: 5,
      title: localization?.trading_points_member_business_header,
      content: localization?.trading_points_member_business_desc1,
      content2: localization?.trading_points_member_business_desc2,
      imgPath: Images.IslemNoktalari5,
    },
  ];
  //avantajlar
  const Avantajlar = [
    {
      id: 1,
      subTabTitle: localization?.trading_points_ind_rep_menu_advantages,
      title: localization?.trading_points_ind_rep_advantages_header,
      subTitle: localization?.trading_points_ind_rep_advantages_subheader1,
      desc1: localization?.trading_points_ind_rep_advantages_desc1,
      subTitle2: localization?.trading_points_ind_rep_advantages_subheader2,
      desc2: localization?.trading_points_ind_rep_advantages_desc2,
      subTitle3: localization?.trading_points_ind_rep_advantages_subheader3,
      desc3: localization?.trading_points_ind_rep_advantages_desc3,

      buttonText: localization?.trading_points_ind_rep_advantages_button_desc,
      imgPath: localization?.trading_points_ind_rep_advantages_media,
    },
    {
      id: 2,
      subTabTitle: localization?.trading_points_ins_rep_menu_advantages,
      title: localization?.trading_points_ins_rep_adv_title,

      subTitle2: localization?.trading_points_ins_rep_advantages_subheader2,
      subTitle: localization?.trading_points_ins_rep_advantages_subheader1,

      desc1: localization?.trading_points_ins_rep_adv_desc1,
      desc2: localization?.trading_points_ins_rep_adv_desc2,
      subTitle3: localization?.trading_points_ins_rep_advantages_subheader3,
      desc3: localization?.trading_points_ins_rep_advantages_desc3,
      imgPath: localization?.trading_points_ins_rep_advantages_media,
      buttonText: localization?.trading_points_ins_rep_adv_button_desc,
    },
    {
      id: 3,
      subTabTitle: localization?.trading_points_member_bus_menu_advantages,
      title: localization?.trading_points_member_bus_adv_title,
      subTitle: localization?.trading_points_member_bus_adv_subheader1,
      desc1: localization?.trading_points_member_bus_adv_desc1,
      subTitle2: localization?.trading_points_member_bus_adv_subheader2,
      desc2: localization?.trading_points_member_bus_adv_desc2,
      subTitle3: localization?.trading_points_member_bus_adv_subheader3,
      desc3: localization?.trading_points_member_bus_adv_desc3,

      imgPath: localization?.trading_points_member_bus_adv_media,
      buttonText: localization?.trading_points_member_bus_adv_button_desc,
    },
  ];
  // başvurular
  const Basvurular = [
    {
      id: 1,
      subTabTitle: localization?.trading_points_ind_rep_menu_apply,
      title: localization?.trading_points_ind_rep_apply_header,
      subTitle1: localization?.trading_points_ind_rep_apply_subheader1,
      desc1: localization?.trading_points_ind_rep_apply_desc1,
      subTitle2: localization?.trading_points_ind_rep_apply_subheader2,
      desc2: localization?.trading_points_ind_rep_apply_desc2,
      subTitle3: localization?.trading_points_ind_rep_apply_subheader3,
      desc4: localization?.trading_points_ind_rep_apply_desc3,
      imgPath: localization?.trading_points_ind_rep_apply_media,
      buttonText: localization?.trading_points_ind_rep_apply_button_desc,
    },
    {
      id: 2,
      subTabTitle: localization?.trading_points_ins_rep_menu_apply,
      title: localization?.trading_points_ins_rep_apply_title,
      subHeader: "",
      subTitle1: localization?.trading_points_ins_rep_apply_subtitle1,
      desc1: localization?.trading_points_ins_rep_apply_desc1,
      subTitle2: localization?.trading_points_ins_rep_apply_subtitle2,
      desc2: localization?.trading_points_ins_rep_apply_desc2,
      subTitle3: localization?.trading_points_ins_rep_apply_subheader3,
      desc3: localization?.trading_points_ins_rep_apply_desc3,
      imgPath: localization?.trading_points_ins_rep_apply_media,
      buttonText: localization?.trading_points_ins_rep_apply_button_desc,
    },
    {
      id: 3,
      subTabTitle: localization?.trading_points_member_bus_menu_apply,
      title: localization?.trading_points_member_bus_apply_title,
      subTitle1: localization?.trading_points_member_bus_apply_subtitle1,
      desc1: localization?.trading_points_member_bus_apply_desc1,
      subTitle2: localization?.trading_points_member_bus_apply_subtitle2,
      desc2: localization?.trading_points_member_bus_apply_desc2,
      subTitle3: localization?.trading_points_member_bus_apply_subheader3,
      desc3: localization?.trading_points_member_bus_apply_desc3,
      buttonText: localization?.trading_points_member_bus_apply_button_desc,

      imgPath: localization?.trading_points_member_bus_apply_media,
    },
  ];
  //uygulama süreçleri
  const UygulamaSurecleri = [
    {
      id: 1,
      subTabTitle:
        localization?.trading_points_ind_rep_menu_application_process,
      subTitle: localization?.trading_points_ind_rep_app_proc_subheader,
      title: localization?.trading_points_ind_rep_app_proc_header,
      subHeader: localization?.trading_points_ind_rep_app_proc_subheader,
      imgPath: localization?.trading_points_ind_rep_app_proc_media,
      desc1: localization?.trading_points_ind_rep_app_proc_desc1,
      subTitle1: localization?.trading_points_ind_rep_app_proc_subheader1,
      desc2: localization?.trading_points_ind_rep_app_proc_desc2,
      subTitle2: localization?.trading_points_ind_rep_app_proc_subheader2,
      desc3: localization?.trading_points_ind_rep_app_proc_desc3,
      button1Text: localization?.trading_points_ind_rep_app_proc_button_1,
      button2Text: localization?.trading_points_ind_rep_app_proc_button_2,
      button3Text: localization?.trading_points_ind_rep_app_proc_button_3,
      button4Text: localization?.trading_points_ind_rep_app_proc_button_4,
    },
    {
      id: 2,
      subTabTitle:
        localization?.trading_points_ins_rep_menu_application_process,
      title: localization?.trading_points_ins_rep_app_proccess_title,
      subTitle: localization?.trading_points_ins_rep_app_proccess_subtitle1,
      desc1: localization?.trading_points_ins_rep_app_proccess_desc1,
      subTitle1: localization?.trading_points_ins_rep_app_proccess_subtitle1,
      desc2: localization?.trading_points_ins_rep_app_proccess_desc2,
      subTitle2: localization?.trading_points_ins_rep_app_proccess_subtitle2,
      desc3: localization?.trading_points_ins_rep_app_proccess_desc3,
      button1Text:
        localization?.trading_points_ins_rep_app_proccess_button_desc1,
      button2Text:
        localization?.trading_points_ins_rep_app_proccess_button_desc2,
      button3Text:
        localization?.trading_points_ins_rep_app_proccess_button_desc3,
      button4Text:
        localization?.trading_points_ins_rep_app_proccess_button_desc4,
      imgPath: localization?.trading_points_ins_rep_app_proc_media,
    },
    {
      id: 3,
      subTabTitle:
        localization?.trading_points_member_bus_menu_application_process,
      title: localization?.trading_points_member_bus_app_proccess_title,
      subHeader: localization?.trading_points_member_bus_app_proc_subheader,
      desc1: localization?.trading_points_member_bus_app_proccess_desc1,
      subTitle1: localization?.trading_points_member_bus_app_proccess_subtitle1,
      desc2: localization?.trading_points_member_bus_app_proccess_desc2,
      subTitle2: localization?.trading_points_member_bus_app_proccess_subtitle2,
      desc3: localization?.trading_points_member_bus_app_proccess_desc3,
      button1Text:
        localization?.trading_points_member_bus_app_proccess_button_desc1,
      button2Text:
        localization?.trading_points_member_bus_app_proccess_button_desc2,
      button3Text:
        localization?.trading_points_member_bus_app_proccess_button_desc3,
      button4Text:
        localization?.trading_points_member_bus_app_proccess_button_desc4,
      imgPath: localization?.trading_points_member_bus_app_proc_media,
    },
  ];
  // faq tab
  const Faqs = [
    {
      id: 1,
      subTabTitle: localization?.trading_points_ind_rep_menu_faq,
      title: localization?.trading_points_ind_rep_faq_header,
    },
    {
      id: 2,
      subTabTitle: localization?.trading_points_ins_rep_menu_faq,
      title: localization?.trading_points_ins_rep_faq_title,
    },
    {
      id: 3,
      subTabTitle: localization?.trading_points_member_bus_menu_faq,
      title: localization?.trading_points_member_bus_faq_title,
    },
  ];

  const Temsilcikler = [
    {
      id: 1,
      subTabTitle: localization?.trading_points_ind_rep_menu_representative,
    },
    {
      id: 2,
      subTabTitle: localization?.trading_points_ins_rep_menu_representative,
    },
    {
      id: 3,
      subTabTitle: localization?.trading_points_member_bus_menu_representative,
    },
  ];

  const [selectedData, setSelectedData] = useState(data[_id]);
  const [sub1SelectedData, setSub1SelectedData] = useState(
    Avantajlar[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
  );
  const [sub2SelectedData, setSub2SelectedData] = useState(
    Basvurular[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
  );
  const [sub3SelectedData, setSub3SelectedData] = useState(
    UygulamaSurecleri[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
  );
  const [sub4SelectedData, setSub4SelectedData] = useState(
    Faqs[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
  );
  const [sub5SelectedData, setSub5SelectedData] = useState(
    Temsilcikler[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
  );
  useEffect(() => {
    if (![3, 4, 5].includes(selectedData?.id)) return; // Bu koşulu ekledik.
    const subCategoryID =
      selectedData?.id === 3
        ? { ExpenseSubProfile: "0", TopUpSubProfile: "1" }
        : selectedData?.id === 4
        ? { ExpenseSubProfile: "0", TopUpSubProfile: "2" }
        : selectedData?.id === 5
        ? { TopUpSubProfile: "0", ExpenseSubProfile: "1" }
        : { TopUpSubProfile: "1", ExpenseSubProfile: "0" };
    dispatch(resetState());
    dispatch(ourRepresentativesRequest(subCategoryID));
  }, [selectedData.id]);
  useEffect(() => {
    const faqCategory =
      selectedData?.id === 3
        ? FaqCategories.BIREYSEL_TEMSILCI
        : selectedData?.id === 4
        ? FaqCategories.KURUMSAL_TEMSILCI
        : selectedData?.id === 5
        ? FaqCategories.UYE_IS_YERI
        : FaqCategories.BIREYSEL_TEMSILCI;
    dispatch(faqsRequest(faqCategory));
  }, [selectedData, sub5SelectedData]);
  useEffect(() => {
    setSelectedData(data[_id]);
    setSub1SelectedData(
      Avantajlar[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
    );
    setSub2SelectedData(
      Basvurular[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
    );
    setSub3SelectedData(
      UygulamaSurecleri[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
    );
    setSub4SelectedData(
      Faqs[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
    );
    setSub5SelectedData(
      Temsilcikler[_id === 2 ? 0 : _id === 3 ? 1 : _id === 4 ? 2 : 0]
    );
  }, [_id]);
  console.log(sub4SelectedData, location.state);
  useEffect(() => {
    if (location.pathname === "/processingPoints/basvuruMerkezi") {
      setBiletmatik(false);
      setIstanbulkartBasvuruMerkezi(true);
      setBireyselTemsilci(false);
      setKurumsalTemsilci(false);
      setUyeIsYeri(false);
      setSelectedData(data[1]);
    } else if (location.pathname == "/processingPoints/biletmatik") {
      setBiletmatik(true);
      setIstanbulkartBasvuruMerkezi(false);
      setBireyselTemsilci(false);
      setKurumsalTemsilci(false);
      setUyeIsYeri(false);
      setSelectedData(data[0]);
    }
    if (location.pathname == "/processingPoints/bireyselTemsilci") {
      setBiletmatik(false);
      setIstanbulkartBasvuruMerkezi(false);
      setBireyselTemsilci(true);
      setKurumsalTemsilci(false);
      setUyeIsYeri(false);
      setSelectedData(data[2]);

      setSub1SelectedData(Avantajlar[0]);
      setSub2SelectedData(Basvurular[0]);
      setSub3SelectedData(UygulamaSurecleri[0]);
      setSub4SelectedData(Faqs[0]);
      setSub5SelectedData(Temsilcikler[0]);
      if (location?.state?.subTab == 0 && !!sub1SelectedData?.subTabTitle) {
        setAvantajlar(true);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 1 && sub2SelectedData.subTabTitle) ||
        (!sub1SelectedData?.subTabTitle &&
          !!sub2SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(true);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 2 && sub3SelectedData.subTabTitle) ||
        (!sub2SelectedData?.subTabTitle &&
          !!sub3SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(true);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 3 && !!sub4SelectedData.subTabTitle) ||
        (!sub3SelectedData?.subTabTitle &&
          !!sub4SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(true);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 4 && sub5SelectedData.subTabTitle) ||
        (!sub4SelectedData?.subTabTitle &&
          !!sub5SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(true);
      } else {
        setAvantajlar(true);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      }
    } else if (location.pathname == "/processingPoints/kurumsalTemsilci") {
      setBiletmatik(false);
      setIstanbulkartBasvuruMerkezi(false);
      setBireyselTemsilci(false);
      setKurumsalTemsilci(true);
      setUyeIsYeri(false);
      setSelectedData(data[3]);

      setSub1SelectedData(Avantajlar[1]);
      setSub2SelectedData(Basvurular[1]);
      setSub3SelectedData(UygulamaSurecleri[1]);
      setSub4SelectedData(Faqs[1]);
      setSub5SelectedData(Temsilcikler[1]);
      if (location?.state?.subTab == 0 && !!sub1SelectedData?.subTabTitle) {
        setAvantajlar(true);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 1 && sub2SelectedData.subTabTitle) ||
        (!sub1SelectedData?.subTabTitle &&
          !!sub2SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(true);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 2 && sub3SelectedData.subTabTitle) ||
        (!sub2SelectedData?.subTabTitle &&
          !!sub3SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(true);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 3 && !!sub4SelectedData.subTabTitle) ||
        (!sub3SelectedData?.subTabTitle &&
          !!sub4SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        debugger;
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(true);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 4 && sub5SelectedData.subTabTitle) ||
        (!sub4SelectedData?.subTabTitle &&
          !!sub5SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(true);
      } else {
        setAvantajlar(true);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      }
    } else if (location.pathname == "/processingPoints/uyeIsYeri") {
      setBiletmatik(false);
      setIstanbulkartBasvuruMerkezi(false);
      setBireyselTemsilci(false);
      setKurumsalTemsilci(false);
      setUyeIsYeri(true);
      setSelectedData(data[4]);

      setSub1SelectedData(Avantajlar[2]);
      setSub2SelectedData(Basvurular[2]);
      setSub3SelectedData(UygulamaSurecleri[2]);
      setSub4SelectedData(Faqs[2]);
      setSub5SelectedData(Temsilcikler[2]);

      if (
        location?.state?.subTab == 0 &&
        !!sub1SelectedData?.subTabTitle &&
        location.state.subTab == 0
      ) {
        setAvantajlar(true);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 1 && sub2SelectedData.subTabTitle) ||
        (!sub1SelectedData?.subTabTitle &&
          !!sub2SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(true);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 2 && sub3SelectedData.subTabTitle) ||
        (!sub2SelectedData?.subTabTitle &&
          !!sub3SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(true);
        setSss(false);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 3 && sub4SelectedData.subTabTitle) ||
        (!sub3SelectedData?.subTabTitle &&
          !!sub4SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(true);
        setTemsilciliklerimiz(false);
      } else if (
        (location?.state?.subTab == 4 && sub5SelectedData.subTabTitle) ||
        (!sub4SelectedData?.subTabTitle &&
          !!sub5SelectedData.subTabTitle &&
          location.state.subTab == 0)
      ) {
        setAvantajlar(false);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(true);
      } else {
        setAvantajlar(true);
        setBasvuru(false);
        setUygulamaSureci(false);
        setSss(false);
        setTemsilciliklerimiz(false);
      }
    }
  }, [pathName, locationState, location.state?.tab]);

  return (
    <>
      {useWindowDimensions().width > 900 ? (
        <div id="processingPoints" className="pageContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 50,
            }}
          >
            <TabBox
              text={localization?.trading_points_menu_biletmatik}
              isActive={biletmatik}
              onPress={() => {
                navigate("/processingPoints/biletmatik");
                setBiletmatik(true);
                setIstanbulkartBasvuruMerkezi(false);
                setBireyselTemsilci(false);
                setKurumsalTemsilci(false);
                setUyeIsYeri(false);
                setSelectedData(data[0]);
                setAvantajlar(true);
                setBasvuru(false);
                setUygulamaSureci(false);
                setSss(false);
                setTemsilciliklerimiz(false);
              }}
            />
            <TabBox
              text={localization?.trading_points_menu_istanbulkart_apply_center}
              isActive={istanbulkartBasvuruMerkezi}
              onPress={() => {
                navigate("/processingPoints/basvuruMerkezi");
                setBiletmatik(false);
                setIstanbulkartBasvuruMerkezi(true);
                setBireyselTemsilci(false);
                setKurumsalTemsilci(false);
                setUyeIsYeri(false);
                setSelectedData(data[1]);
                setAvantajlar(true);
                setBasvuru(false);
                setUygulamaSureci(false);
                setSss(false);
                setTemsilciliklerimiz(false);
              }}
            />
            <TabBox
              text={localization?.trading_points_menu_individual_representative}
              isActive={bireyselTemsilci}
              onPress={() => {
                navigate("/processingPoints/bireyselTemsilci", {
                  state: {
                    id: 2,
                    subTab: 0,
                  },
                });

                setBiletmatik(false);
                setIstanbulkartBasvuruMerkezi(false);
                setBireyselTemsilci(true);
                setKurumsalTemsilci(false);
                setUyeIsYeri(false);
                setSelectedData(data[2]);
                setAvantajlar(true);
                setBasvuru(false);
                setUygulamaSureci(false);
                setSss(false);
                setTemsilciliklerimiz(false);
                setSub1SelectedData(Avantajlar[0]);
                setSub2SelectedData(Basvurular[0]);
                setSub3SelectedData(UygulamaSurecleri[0]);
                setSub4SelectedData(Faqs[0]);
                setSub5SelectedData(Temsilcikler[0]);
              }}
            />
            <TabBox
              text={
                localization?.trading_points_menu_institutional_representative
              }
              isActive={kurumsalTemsilci}
              onPress={() => {
                navigate("/processingPoints/kurumsalTemsilci", {
                  state: {
                    id: 3,
                    tab: 3,
                    subTab: 0,
                  },
                });
                setBiletmatik(false);
                setIstanbulkartBasvuruMerkezi(false);
                setBireyselTemsilci(false);
                setKurumsalTemsilci(true);
                setUyeIsYeri(false);
                setSelectedData(data[3]);
                setAvantajlar(true);
                setBasvuru(false);
                setUygulamaSureci(false);
                setSss(false);
                setTemsilciliklerimiz(false);
                setSub1SelectedData(Avantajlar[1]);
                setSub2SelectedData(Basvurular[1]);
                setSub3SelectedData(UygulamaSurecleri[1]);
                setSub4SelectedData(Faqs[1]);
                setSub5SelectedData(Temsilcikler[1]);
              }}
            />
            <TabBox
              text={localization?.trading_points_menu_member_bussiness}
              isActive={uyeIsYeri}
              onPress={() => {
                navigate("/processingPoints/uyeIsYeri", {
                  state: {
                    id: 4,
                  },
                });
                setBiletmatik(false);
                setIstanbulkartBasvuruMerkezi(false);
                setBireyselTemsilci(false);
                setKurumsalTemsilci(false);
                setUyeIsYeri(true);
                setSelectedData(data[4]);
                setAvantajlar(true);
                setBasvuru(false);
                setUygulamaSureci(false);
                setSss(false);
                setTemsilciliklerimiz(false);
                setSub1SelectedData(Avantajlar[2]);
                setSub2SelectedData(Basvurular[2]);
                setSub3SelectedData(UygulamaSurecleri[2]);
                setSub4SelectedData(Faqs[2]);
                setSub5SelectedData(Temsilcikler[2]);
              }}
            />
          </div>
          {[3, 4, 5].includes(selectedData?.id) && (
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 33 }}
            >
              {sub1SelectedData?.subTabTitle && (
                <SectionTab
                  text={sub1SelectedData?.subTabTitle}
                  isActive={!!sub1SelectedData?.subTabTitle && avantajlar}
                  onPress={() => {
                    navigate(`${pathName}`, {
                      state: { subTab: 0 },
                    });
                    // setAvantajlar(true);
                    // setBasvuru(false);
                    // setUygulamaSureci(false);
                    // setSss(false);
                    // setTemsilciliklerimiz(false);
                  }}
                />
              )}
              {sub2SelectedData?.subTabTitle && (
                <SectionTab
                  text={sub2SelectedData?.subTabTitle}
                  isActive={basvuru}
                  onPress={() => {
                    navigate(`${pathName}`, {
                      state: { subTab: 1 },
                    });

                    // setAvantajlar(false);
                    // setBasvuru(true);
                    // setUygulamaSureci(false);
                    // setSss(false);
                    // setTemsilciliklerimiz(false);
                  }}
                />
              )}
              {sub3SelectedData?.subTabTitle && (
                <SectionTab
                  text={sub3SelectedData?.subTabTitle}
                  isActive={uygulamaSureci}
                  onPress={() => {
                    navigate(`${pathName}`, {
                      state: { subTab: 2 },
                    });

                    // setAvantajlar(false);
                    // setBasvuru(false);
                    // setUygulamaSureci(true);
                    // setSss(false);
                    // setTemsilciliklerimiz(false);
                  }}
                />
              )}
              {sub4SelectedData?.subTabTitle && (
                <SectionTab
                  text={sub4SelectedData?.subTabTitle}
                  isActive={sss}
                  onPress={() => {
                    navigate(`${pathName}`, {
                      state: { subTab: 3 },
                    });

                    // setAvantajlar(false);
                    // setBasvuru(false);
                    // setUygulamaSureci(false);
                    // setSss(true);
                    // setTemsilciliklerimiz(false);
                  }}
                />
              )}
              {sub5SelectedData?.subTabTitle && (
                <SectionTab
                  text={sub5SelectedData?.subTabTitle}
                  isActive={temsilciliklerimiz}
                  onPress={() => {
                    navigate(`${pathName}`, {
                      state: { subTab: 4 },
                    });

                    // setAvantajlar(false);
                    // setBasvuru(false);
                    // setUygulamaSureci(false);
                    // setSss(false);
                    // setTemsilciliklerimiz(true);
                  }}
                />
              )}
            </div>
          )}
          <div>
            <div className="processingPoints__container">
              <div
                style={{
                  width: "100%",
                }}
              >
                {[3, 4, 5].includes(selectedData?.id) ? (
                  avantajlar ? (
                    <div
                      className="processingPoints__container"
                      style={{ marginTop: "0" }}
                    >
                      <div>
                        <Typography variant="h2" align="center">
                          <Box
                            fontWeight={700}
                            className="processingPoints__title"
                          >
                            {sub1SelectedData?.title}
                          </Box>
                        </Typography>
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                            dangerouslySetInnerHTML={{
                              __html: makeLink(
                                sub1SelectedData?.subTitle || ""
                              ),
                            }}
                          ></Box>
                        </Typography>
                        <p
                          className="processingPoints__content"
                          dangerouslySetInnerHTML={{
                            __html: makeLink(sub1SelectedData?.desc1),
                          }}
                        ></p>
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                            dangerouslySetInnerHTML={{
                              __html: makeLink(
                                sub1SelectedData?.subTitle2 || ""
                              ),
                            }}
                          ></Box>
                        </Typography>
                        {sub1SelectedData?.desc2 && (
                          <p
                            className="processingPoints__content"
                            dangerouslySetInnerHTML={{
                              __html: sub1SelectedData?.desc2
                                ? sub1SelectedData?.desc2
                                : "",
                            }}
                          ></p>
                        )}
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                            dangerouslySetInnerHTML={{
                              __html: makeLink(
                                sub1SelectedData?.subTitle3 || ""
                              ),
                            }}
                          ></Box>
                        </Typography>
                        {sub1SelectedData?.desc3 && (
                          <p
                            className="processingPoints__content"
                            dangerouslySetInnerHTML={{
                              __html: sub1SelectedData?.desc3
                                ? sub1SelectedData?.desc3
                                : "",
                            }}
                          ></p>
                        )}
                        <div style={{ height: 20 }} />
                        <div
                          className="processingPoints__button"
                          onClick={() => {
                            let newWindow: any = window.open();
                            newWindow.location =
                              "https://kurumsal.belbim.istanbul/";
                          }}
                        >
                          <Link to="#">{sub1SelectedData?.buttonText}</Link>
                        </div>
                      </div>

                      <div style={{}}>
                        <img
                          height={510}
                          src={sub1SelectedData?.imgPath}
                          alt={sub1SelectedData?.title}
                        />
                      </div>
                    </div>
                  ) : basvuru ? (
                    <div
                      className="processingPoints__container"
                      style={{ marginTop: "0" }}
                    >
                      {" "}
                      <div>
                        <Typography variant="h2" align="center">
                          <Box
                            fontWeight={700}
                            className="processingPoints__title"
                          >
                            {sub2SelectedData?.title}
                          </Box>
                        </Typography>
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                            dangerouslySetInnerHTML={{
                              __html: makeLink(
                                sub2SelectedData?.subHeader || ""
                              ),
                            }}
                          ></Box>
                        </Typography>
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                          >
                            {sub2SelectedData?.subTitle1}
                          </Box>
                        </Typography>
                        {sub2SelectedData?.desc1 &&
                          sub2SelectedData?.desc1
                            .split("#")
                            .map((item, index) => {
                              return (
                                <>
                                  {item !== "" && (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p
                                        className="processingPoints__content"
                                        dangerouslySetInnerHTML={{
                                          __html: makeLink(item),
                                        }}
                                      ></p>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                          >
                            {sub2SelectedData?.subTitle2}
                          </Box>
                        </Typography>
                        {sub2SelectedData?.desc2 && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className="processingPoints__content"
                              dangerouslySetInnerHTML={{
                                __html: sub2SelectedData?.desc2,
                              }}
                            ></p>
                          </div>
                        )}
                        {sub2SelectedData?.subTitle3 && (
                          <Typography variant="h5" align="left">
                            <Box
                              fontWeight={700}
                              className="processingPoints__basvuruTitle"
                            >
                              {`${sub2SelectedData?.subTitle3}`}
                            </Box>
                          </Typography>
                        )}
                        {sub2SelectedData?.desc3 && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className="processingPoints__content"
                              dangerouslySetInnerHTML={{
                                __html: `${sub2SelectedData?.desc3 || "ascsa"}`,
                              }}
                            ></p>
                          </div>
                        )}
                        {sub2SelectedData?.desc4 && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className="processingPoints__content"
                              dangerouslySetInnerHTML={{
                                __html: sub2SelectedData?.desc4,
                              }}
                            ></p>
                          </div>
                        )}

                        <div style={{ height: 20 }} />
                        <div
                          className="processingPoints__button"
                          onClick={() => {
                            let newWindow: any = window.open();
                            newWindow.location =
                              "https://kurumsal.belbim.istanbul/";
                          }}
                        >
                          <Link to="#">{sub2SelectedData?.buttonText}</Link>
                        </div>
                      </div>
                      <div style={{}}>
                        <img
                          height={510}
                          src={sub2SelectedData?.imgPath}
                          alt={sub2SelectedData?.title}
                        />
                      </div>
                    </div>
                  ) : uygulamaSureci ? (
                    <div
                      className="processingPoints__container"
                      style={{ marginTop: "0" }}
                    >
                      {" "}
                      <div>
                        <Typography variant="h2" align="center">
                          <Box
                            fontWeight={700}
                            className="processingPoints__title"
                          >
                            {sub3SelectedData?.title}
                          </Box>
                        </Typography>
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                            dangerouslySetInnerHTML={{
                              __html: makeLink(
                                sub3SelectedData?.subHeader ||
                                  sub3SelectedData?.subTitle ||
                                  ""
                              ),
                            }}
                          ></Box>
                        </Typography>
                        <p
                          className="processingPoints__content"
                          dangerouslySetInnerHTML={{
                            __html: makeLink(sub3SelectedData?.desc1),
                          }}
                        ></p>
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                          >
                            {sub3SelectedData?.subTitle1}
                          </Box>
                        </Typography>
                        <p
                          className="processingPoints__content"
                          dangerouslySetInnerHTML={{
                            __html: makeLink(sub3SelectedData?.desc2),
                          }}
                        ></p>
                        <Typography variant="h5" align="left">
                          <Box
                            fontWeight={700}
                            className="processingPoints__basvuruTitle"
                          >
                            {sub3SelectedData?.subTitle2}
                          </Box>
                        </Typography>
                        <p
                          className="processingPoints__content"
                          dangerouslySetInnerHTML={{
                            __html: makeLink(sub3SelectedData?.desc3),
                          }}
                        ></p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          {sub3SelectedData?.button1Text && (
                            <div className="processingPoints__button">
                              <a
                                href="/assets/temsilcibilgilendirmeformu.pdf"
                                download={sub3SelectedData?.button1Text}
                              >
                                {sub3SelectedData?.button1Text}
                              </a>
                            </div>
                          )}
                          {sub3SelectedData?.button2Text && (
                            <div className="processingPoints__button">
                              <a
                                href="/assets/temsilcilikelkitabi.pdf"
                                download={sub3SelectedData?.button2Text}
                              >
                                {sub3SelectedData?.button2Text}
                              </a>
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          {sub3SelectedData?.button3Text && (
                            <div className="processingPoints__button">
                              <a
                                href="/assets/uzaktandolumkullanimkilavuzu.pdf"
                                download={sub3SelectedData?.button3Text}
                              >
                                {sub3SelectedData?.button3Text}
                              </a>
                            </div>
                          )}
                          {sub3SelectedData?.button4Text && (
                            <div className="processingPoints__button">
                              <a
                                href="/assets/yeninesilkullanatkartaktivasyonkilavuzu.pdf"
                                download={sub3SelectedData?.button4Text}
                              >
                                {sub3SelectedData?.button4Text}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{}}>
                        <img
                          height={510}
                          src={sub3SelectedData?.imgPath}
                          alt={sub3SelectedData?.title}
                        />
                      </div>
                    </div>
                  ) : temsilciliklerimiz ? (
                    loading ? (
                      <div
                        style={{
                          maxWidth: "fit-content",
                          marginInline: "auto",
                        }}
                      >
                        {
                          localization?.trading_points_all_representatives_loading
                        }
                      </div>
                    ) : (
                      <OurRepresentativesList
                        data={Representatives}
                        tab={selectedData?.id}
                      />
                    )
                  ) : sss ? (
                    <>
                      <Typography variant="h5" align="center">
                        <Box
                          fontWeight={700}
                          className="processingPoints__title2"
                        >
                          {sub4SelectedData?.title}
                        </Box>
                      </Typography>
                      <SearchField
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        placeHolder={
                          localization?.trading_points_ind_rep_faq_search_desc
                        }
                        keyValue={"mask0_726_375faqs"}
                      />
                      <div style={{ height: 30 }} />
                      {dataFiltered &&
                        dataFiltered.map((item: any, id: number) => (
                          <FaqsItem
                            id={id}
                            title={item.Question}
                            key={id}
                            itemID={""}
                          />
                        ))}
                    </>
                  ) : (
                    <div></div>
                  )
                ) : (
                  <>
                    <Typography variant="h2" align="center">
                      <Box fontWeight={700} className="processingPoints__title">
                        {selectedData?.title}
                      </Box>
                    </Typography>

                    <Typography variant="h5" align="left">
                      <Box
                        fontWeight={700}
                        className="processingPoints__basvuruTitle"
                        dangerouslySetInnerHTML={{
                          __html: makeLink(selectedData?.subTabTitle || ""),
                        }}
                      ></Box>
                    </Typography>
                    <p
                      className="processingPoints__content"
                      dangerouslySetInnerHTML={{
                        __html: selectedData?.content,
                      }}
                    ></p>
                    <Typography variant="h5" align="left">
                      <Box
                        fontWeight={700}
                        className="processingPoints__basvuruTitle"
                        dangerouslySetInnerHTML={{
                          __html: makeLink(selectedData?.subTabTitle2 || ""),
                        }}
                      ></Box>
                    </Typography>
                    {selectedData?.content2 && (
                      <p
                        className="processingPoints__content"
                        dangerouslySetInnerHTML={{
                          __html: makeLink(selectedData?.content2),
                        }}
                      ></p>
                    )}
                    <Typography variant="h5" align="left">
                      <Box
                        fontWeight={700}
                        className="processingPoints__basvuruTitle"
                        dangerouslySetInnerHTML={{
                          __html: makeLink(selectedData?.subTabTitle3 || ""),
                        }}
                      ></Box>
                    </Typography>
                    {selectedData?.content3 && (
                      <p
                        className="processingPoints__content"
                        dangerouslySetInnerHTML={{
                          __html: makeLink(selectedData?.content3),
                        }}
                      ></p>
                    )}
                    {selectedData?.content4 && (
                      <p
                        className="processingPoints__content"
                        dangerouslySetInnerHTML={{
                          __html: makeLink(selectedData?.content4),
                        }}
                      ></p>
                    )}
                  </>
                )}
              </div>
              {!temsilciliklerimiz &&
                !sss &&
                ![3, 4, 5].includes(selectedData.id) && (
                  <div style={{}}>
                    <img
                      height={510}
                      src={selectedData?.imgPath}
                      alt={selectedData?.title}
                    />
                  </div>
                )}
            </div>
            {basvuru && <OurRepresentatives />}
          </div>
        </div>
      ) : (
        <div id="processingPoints" className="pageContainer">
          <div
            style={{
              width: "100%",
              height: 2,
              backgroundColor: "#F2F4F6",
              marginBottom: 17,
            }}
          />
          <Typography
            className="processingPoints__processingPointsMobile__title"
            variant="h6"
            align="left"
          >
            <Box
              fontSize={fontSizes.regular}
              style={{ display: "flex", alignItems: "center" }}
            >
              {localization?.homepage_menu_transaction_points}
              <RightArrowGray /> {selectedData?.title}
            </Box>
          </Typography>
          <Typography variant="h5" align="center">
            <Box
              fontWeight={700}
              className="processingPoints__processingPointsMobile__header"
            >
              {selectedData?.title}
            </Box>
          </Typography>
          {[3, 4, 5].includes(selectedData?.id) && (
            <Swiper
              modules={[Pagination, A11y]}
              slidesPerView={3.4}
              //pagination={true}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
            >
              {sub1SelectedData?.subTabTitle && (
                <SwiperSlide>
                  <SectionTab
                    text={sub1SelectedData?.subTabTitle}
                    isActive={avantajlar}
                    onPress={() => {
                      setAvantajlar(true);
                      setBasvuru(false);
                      setUygulamaSureci(false);
                      setSss(false);
                      setTemsilciliklerimiz(false);
                    }}
                  />
                </SwiperSlide>
              )}
              {sub2SelectedData?.subTabTitle && (
                <SwiperSlide>
                  <SectionTab
                    text={sub2SelectedData?.subTabTitle}
                    isActive={basvuru}
                    onPress={() => {
                      setAvantajlar(false);
                      setBasvuru(true);
                      setUygulamaSureci(false);
                      setSss(false);
                      setTemsilciliklerimiz(false);
                    }}
                  />
                </SwiperSlide>
              )}
              {sub3SelectedData?.subTabTitle && (
                <SwiperSlide>
                  <SectionTab
                    text={sub3SelectedData?.subTabTitle}
                    isActive={uygulamaSureci}
                    onPress={() => {
                      setAvantajlar(false);
                      setBasvuru(false);
                      setUygulamaSureci(true);
                      setSss(false);
                      setTemsilciliklerimiz(false);
                    }}
                  />
                </SwiperSlide>
              )}
              {sub4SelectedData?.subTabTitle && (
                <SwiperSlide>
                  <SectionTab
                    text={sub4SelectedData?.subTabTitle}
                    isActive={sss}
                    onPress={() => {
                      setAvantajlar(false);
                      setBasvuru(false);
                      setUygulamaSureci(false);
                      setSss(true);
                      setTemsilciliklerimiz(false);
                    }}
                  />
                </SwiperSlide>
              )}
              {sub5SelectedData?.subTabTitle && (
                <SwiperSlide>
                  <SectionTab
                    text={sub5SelectedData?.subTabTitle}
                    isActive={temsilciliklerimiz}
                    onPress={() => {
                      setAvantajlar(false);
                      setBasvuru(false);
                      setUygulamaSureci(false);
                      setSss(false);
                      setTemsilciliklerimiz(true);
                    }}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 28,
            }}
          >
            {!temsilciliklerimiz &&
              !sss &&
              ![3, 4, 5].includes(selectedData.id) && (
                <div style={{}}>
                  <img
                    height={510}
                    src={selectedData?.imgPath}
                    alt={selectedData?.title}
                  />
                </div>
              )}
            {[3, 4, 5].includes(selectedData?.id) &&
              (avantajlar ? (
                <>
                  <div style={{}}>
                    <img
                      height={510}
                      src={sub1SelectedData?.imgPath}
                      alt={sub1SelectedData?.title}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" align="center">
                      <Box
                        fontWeight={700}
                        className="processingPoints__processingPointsMobile__title2"
                      >
                        {sub1SelectedData?.title}
                      </Box>
                    </Typography>
                    <p
                      className="processingPoints__processingPointsMobile__content"
                      dangerouslySetInnerHTML={{
                        __html: makeLink(sub1SelectedData?.desc1),
                      }}
                    ></p>
                    <p className="processingPoints__processingPointsMobile__content">
                      {sub1SelectedData?.desc2 &&
                        sub1SelectedData?.desc2
                          .split("#")
                          .map((item, index) => {
                            return (
                              <>
                                {item !== "" && (
                                  <p
                                    key={index}
                                    className="processingPoints__content"
                                    dangerouslySetInnerHTML={{
                                      __html: makeLink(item),
                                    }}
                                  ></p>
                                )}
                              </>
                            );
                          })}
                    </p>
                    <div
                      className="processingPoints__processingPointsMobile__button"
                      onClick={() => {
                        let newWindow: any = window.open();
                        newWindow.location =
                          "https://kurumsal.belbim.istanbul/";
                      }}
                    >
                      <Link to="#">{sub1SelectedData?.buttonText}</Link>
                    </div>
                  </div>
                </>
              ) : basvuru ? (
                <>
                  <div style={{}}>
                    <img
                      height={510}
                      src={sub2SelectedData?.imgPath}
                      alt={sub2SelectedData?.title}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" align="center">
                      <Box
                        fontWeight={700}
                        className="processingPoints__processingPointsMobile__title2"
                      >
                        {sub2SelectedData?.title}
                      </Box>
                    </Typography>
                    <div style={{ height: 20 }} />
                    <Typography variant="h5" align="left">
                      <Box
                        fontWeight={700}
                        className="processingPoints__processingPointsMobile__basvuruTitle"
                      >
                        {sub2SelectedData?.subTitle1}
                      </Box>
                    </Typography>
                    {sub2SelectedData?.desc1 &&
                      sub2SelectedData?.desc1.split("#").map((item, index) => {
                        return (
                          <p
                            key={index}
                            className="processingPoints__processingPointsMobile__content"
                          >
                            {item !== "" && <>{item}</>}
                          </p>
                        );
                      })}
                    <Typography variant="h5" align="left">
                      <Box
                        fontWeight={700}
                        className="processingPoints__processingPointsMobile__basvuruTitle"
                      >
                        {sub2SelectedData?.subTitle2}
                      </Box>
                    </Typography>
                    {sub2SelectedData?.desc2 &&
                      sub2SelectedData?.desc2.split("#").map((item, index) => {
                        return (
                          <>
                            {item !== "" && (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  className="processingPoints__content"
                                  dangerouslySetInnerHTML={{
                                    __html: makeLink(item),
                                  }}
                                ></p>
                              </div>
                            )}
                          </>
                        );
                      })}
                    <div
                      className="processingPoints__processingPointsMobile__button"
                      onClick={() => {
                        let newWindow: any = window.open();
                        newWindow.location =
                          "https://kurumsal.belbim.istanbul/";
                      }}
                    >
                      <Link to="#">{sub2SelectedData?.buttonText}</Link>
                    </div>
                  </div>
                </>
              ) : uygulamaSureci ? (
                <>
                  <div style={{}}>
                    <img
                      height={510}
                      src={sub3SelectedData?.imgPath}
                      alt={sub3SelectedData?.title}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="processingPoints__processingPointsMobile__content"
                      dangerouslySetInnerHTML={{
                        __html: makeLink(sub3SelectedData?.desc1),
                      }}
                    ></p>
                    <Typography variant="h5" align="left">
                      <Box
                        fontWeight={700}
                        className="processingPoints__basvuruTitle"
                      >
                        {sub3SelectedData?.subTitle1}
                      </Box>
                    </Typography>
                    <p
                      className="processingPoints__processingPointsMobile__content"
                      dangerouslySetInnerHTML={{
                        __html: makeLink(sub3SelectedData?.desc2),
                      }}
                    ></p>
                    <Typography variant="h5" align="left">
                      <Box
                        fontWeight={700}
                        className="processingPoints__basvuruTitle"
                      >
                        {sub3SelectedData?.subTitle2}
                      </Box>
                    </Typography>
                    <p
                      className="processingPoints__processingPointsMobile__content"
                      dangerouslySetInnerHTML={{
                        __html: makeLink(sub3SelectedData?.desc3),
                      }}
                    ></p>
                    {sub3SelectedData?.button1Text && (
                      <div className="processingPoints__processingPointsMobile__button">
                        <a
                          href="/assets/temsilcibilgilendirmeformu.pdf"
                          download={sub3SelectedData?.button1Text}
                        >
                          {sub3SelectedData?.button1Text}
                        </a>
                      </div>
                    )}

                    {sub3SelectedData?.button2Text && (
                      <div className="processingPoints__processingPointsMobile__button">
                        <a
                          href="/assets/temsilcilikelkitabi.pdf"
                          download={sub3SelectedData?.button2Text}
                        >
                          {sub3SelectedData?.button2Text}
                        </a>
                      </div>
                    )}

                    {sub3SelectedData?.button3Text && (
                      <div className="processingPoints__processingPointsMobile__button">
                        <a
                          href="/assets/uzaktandolumkullanimkilavuzu.pdf"
                          download="Uzaktan Dolum Kullanım Kılavuzu"
                        >
                          {sub3SelectedData?.button3Text}
                        </a>
                      </div>
                    )}

                    {sub3SelectedData?.button4Text && (
                      <div className="processingPoints__processingPointsMobile__button">
                        <a
                          href="/assets/yeninesilkullanatkartaktivasyonkilavuzu.pdf"
                          download={sub3SelectedData?.button4Text}
                        >
                          {sub3SelectedData?.button4Text}
                        </a>
                      </div>
                    )}
                  </div>
                </>
              ) : temsilciliklerimiz ? (
                <OurRepresentativesList
                  data={Representatives}
                  tab={selectedData?.id}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: "",
                    width: "100%",
                  }}
                >
                  <SearchField
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    placeHolder={
                      localization?.trading_points_ind_rep_faq_search_desc
                    }
                    keyValue={"mask0_726_375faqs"}
                  />
                  <div style={{ height: 30 }} />
                  {dataFiltered &&
                    dataFiltered.map((item: any, id: number) => (
                      <FaqsItem
                        id={id}
                        title={item.Question}
                        key={id}
                        itemID={""}
                      />
                    ))}
                </div>
              ))}
            {![3, 4, 5].includes(selectedData?.id) && (
              <>
                <p
                  className="processingPoints__content"
                  dangerouslySetInnerHTML={{ __html: selectedData?.content }}
                ></p>
                {selectedData?.content2 && (
                  <p
                    className="processingPoints__content"
                    dangerouslySetInnerHTML={{
                      __html: makeLink(selectedData?.content2),
                    }}
                  ></p>
                )}
              </>
            )}
            {[3, 4, 5].includes(selectedData?.id) && basvuru && (
              <OurRepresentatives />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ProcessingPoints;

const FaqsItem: FC<FaqsItemType> = memo(({ title, itemID, id }) => {
  const navigate = useNavigate();
  return (
    <div
      className="processingPoints__processingPointsMobile__faqs__item"
      onClick={() => {
        navigate({
          pathname: routePath.faqsDetail,
          search: `?id=${id}`,
        });
      }}
      itemID={itemID}
    >
      <Typography
        className="processingPoints__processingPointsMobile__faqs__item__title"
        variant="h6"
        align="left"
      >
        <Box fontWeight="bold" fontSize={fontSizes.regular}>
          {title}
        </Box>
      </Typography>
      {useWindowDimensions().width < 959 ? (
        <ChevronDarkRightIcon color="#383C52" />
      ) : (
        <ChevronDarkRightIcon />
      )}
    </div>
  );
});
