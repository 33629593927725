import { GetFaqsModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getFaqs } from "Services/Queries";
import { faqsDetailRequest, faqsDetailRequestSuccess } from "./index";
import { globalStore } from "Store/global";
import { PayloadAction } from "@reduxjs/toolkit";
import getFaqsDetail from "Services/Queries/GetFaqsDetail";

function* handleFaqsDetailRequest({ payload }: any) {
  try {
    console.log(payload);
    const { languageId } = yield select(globalStore);
    const getFaqsResult: GetFaqsModel & void = yield call(() =>
      getFaqsDetail({ languageId: languageId, Id: payload })
    );

    yield put(faqsDetailRequestSuccess(getFaqsResult));
  } catch (error) {}
}

function* faqDetailSaga() {
  yield takeLatest(faqsDetailRequest, handleFaqsDetailRequest);
}

export default faqDetailSaga;
