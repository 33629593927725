import { RouteProps } from "react-router-dom";
import {
  Welcome,
  Page1,
  Page2,
  Announcements,
  AnnouncementsDetail,
  Security,
  News,
  NewsDetail,
  MediaMaterials,
  LegalText,
  LegalTextDetail,
  ProcessingPoints,
  Contact,
  Sitemap,
  Institutional,
  ProductsAndServices,
  Faqs,
  FaqsDetail,
  OnlineTransactions,
  Personalization,
  SearchListPage,
  InformationSocietyServices,
} from "../Pages";

type RoutesType = {
  isPrivate?: boolean;
  component?: any;
  exact?: any;
  state?: any;
} & RouteProps;

export const routePath = {
  welcome: "/",
  page1: "/page1",
  page2: "/page2",
  announcements: "/announcements",
  announcementsDetail: "/announcementsDetail",
  security: "/security",
  news: "/news",
  newsDetail: "/newsDetail",
  mediaMaterials: "/mediaMaterials",
  legalText: "/legalText",
  legalTextDetail: "/legalTextDetail",
  contact: "/contact",
  sitemap: "sitemap",
  institutional: "/institutional",
  institutionalAboutUs: "/institutional?page=about-us",
  productsAndServices: "/productsAndServices",
  faqs: "/faqs",
  faqsDetail: "/faqsDetail",
  onlineTransactions: "/onlineTransactions",
  personalization: "/personalization",
  searchListPage: "/searchListPage",
  informationSocietyServices: "/informationSocietyServices",
  processingPoints: "/processingPoints",
  biletmatik: "/processingPoints/biletmatik",
  basvuruMerkezi: "/processingPoints/basvuruMerkezi",
  bireyselTemsilci: "/processingPoints/bireyselTemsilci",
  kurumsalTemsilci: "/processingPoints/kurumsalTemsilci",
  uyeIsYeri: "/processingPoints/uyeIsYeri",
};

export const routes: RoutesType[] = [
  {
    isPrivate: false,
    path: routePath.welcome,
    component: Welcome,
  },
  {
    isPrivate: false,
    path: routePath.page1,
    component: Page1,
  },
  {
    isPrivate: false,
    path: routePath.page2,
    component: Page2,
  },
  {
    isPrivate: false,
    path: routePath.announcements,
    component: Announcements,
  },
  {
    isPrivate: false,
    path: routePath.announcementsDetail,
    component: AnnouncementsDetail,
  },
  {
    isPrivate: false,
    path: routePath.security,
    component: Security,
  },
  {
    isPrivate: false,
    path: routePath.news,
    component: News,
  },
  {
    isPrivate: false,
    path: routePath.newsDetail,
    component: NewsDetail,
  },
  {
    isPrivate: false,
    path: routePath.mediaMaterials,
    component: MediaMaterials,
  },
  {
    isPrivate: false,
    path: routePath.legalText,
    component: LegalText,
  },
  {
    isPrivate: false,
    path: routePath.legalTextDetail,
    component: LegalTextDetail,
  },
  {
    isPrivate: false,
    path: routePath.processingPoints,
    component: ProcessingPoints,
  },
  {
    isPrivate: false,
    path: routePath.contact,
    component: Contact,
  },
  {
    isPrivate: false,
    path: routePath.sitemap,
    component: Sitemap,
  },
  {
    isPrivate: false,
    path: routePath.institutional,
    component: Institutional,
  },
  {
    isPrivate: false,
    path: routePath.productsAndServices,
    component: ProductsAndServices,
  },
  {
    isPrivate: false,
    path: routePath.faqs,
    component: Faqs,
  },
  {
    isPrivate: false,
    path: routePath.faqsDetail,
    component: FaqsDetail,
  },
  {
    isPrivate: false,
    path: routePath.onlineTransactions,
    component: OnlineTransactions,
  },
  {
    isPrivate: false,
    path: routePath.personalization,
    component: Personalization,
  },
  {
    isPrivate: false,
    path: routePath.searchListPage,
    component: SearchListPage,
  },
  {
    isPrivate: false,
    path: routePath.informationSocietyServices,
    component: InformationSocietyServices,
  },
  //
  {
    isPrivate: false,
    path: routePath.biletmatik,
    component: ProcessingPoints,
  },
  {
    isPrivate: false,
    path: routePath.basvuruMerkezi,
    component: ProcessingPoints,
  },
  {
    isPrivate: false,
    path: routePath.bireyselTemsilci,
    component: ProcessingPoints,
  },

  {
    isPrivate: false,
    path: routePath.kurumsalTemsilci,
    component: ProcessingPoints,
  },
  {
    isPrivate: false,
    path: routePath.uyeIsYeri,
    component: ProcessingPoints,
  },
];
