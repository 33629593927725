import { Pagination, SearchField, SelectList } from "Components";
import { useLocalization } from "Hooks";
import { LocationBlue } from "Icons";
import { RootState } from "Store";
import { townListRequest } from "Store/getTownList";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./OurRepresentativesList.scss";

const PageSize = 10;

interface DataItem {
  Address: string | null;
  MerchantName: string | null;
  Town: string | null;
  City: string | null;
  MerchantNumber: string | null;
}

type OurRepresentativesProps = { data: any; tab: number };

const App: FunctionComponent<OurRepresentativesProps> = ({ data, tab }) => {
  const dispatch = useDispatch();
  const localization = useLocalization();
  const { Cities } = useSelector((state: RootState) => state.cityList);
  const { TownList } = useSelector((state: RootState) => state.townList);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [il, setIl] = useState<any>(null);
  const [ilce, setIlce] = useState<any>(null);
  const [search, setSearch] = useState("");

  const targetCities = new Set(
    ["İSTANBUL", "KOCAELİ", "TEKİRDAĞ", "YALOVA"].map((city) =>
      city.toLocaleLowerCase()
    )
  );
  const [tableData] = useState(
    data?.code
      ? []
      : data && Array.isArray(data)
      ? data?.filter((item: any) =>
          targetCities.has(item.City.toLocaleLowerCase())
        )
      : []
  );

  const [filteredData, setFilteredData] = useState<any>([]);

  const filterData = (
    query: { label: string } | string,
    data: DataItem[]
  ): DataItem[] => {
    const queryLabel = typeof query === "object" ? query.label : query;

    if (!queryLabel) return data;
    return data
      .filter((item) =>
        [
          item.Address,
          item.MerchantName,
          item.Town,
          item.City,
          item.MerchantNumber,
        ].some((field) =>
          field
            ?.toLocaleLowerCase("tr")
            .includes(queryLabel.toLocaleLowerCase("tr"))
        )
      )
      .sort((a, b) => {
        const nameA = a.MerchantName?.toLocaleUpperCase() || "";
        const nameB = b.MerchantName?.toLocaleUpperCase() || "";
        return nameA.localeCompare(nameB);
      });
  };

  const currentTableData = useMemo(() => {
    const startIndex = (currentPage - 1) * PageSize;
    return filteredData.slice(startIndex, startIndex + PageSize);
  }, [currentPage, filteredData, tab]);

  useEffect(() => {
    if (il) dispatch(townListRequest({ ilKayitNo: il.value }));
  }, [il, dispatch]);

  useEffect(() => {
    setFilteredData(filterData(searchQuery, tableData));
  }, [searchQuery, tableData]);

  const handleFilterChange = (newIl: any, newIlce: any, newSearch: string) => {
    setCurrentPage(1);
    let filtered = tableData;
    if (newIl) filtered = filterData(newIl, filtered);
    if (newIlce) filtered = filterData(newIlce, filtered);
    if (newSearch) filtered = filterData(newSearch, filtered);
    setFilteredData(filtered);
  };

  return (
    <div className="app-container">
      <div className="filterArea">
        <div className="filterArea__container">
          <SelectList
            selectedValue={il}
            data={Cities?.filter((item) =>
              targetCities.has(item.ilAdi.toLocaleLowerCase())
            ).map((item) => ({
              value: item.ilKodu,
              label: item.ilAdi,
            }))}
            placeholder={
              localization?.trading_points_ind_rep_rep_city_search_desc
            }
            onChangeValue={(val: any) => {
              setIl(val);
              setIlce(null);
              handleFilterChange(val, ilce, search);
            }}
          />
        </div>
        <div style={{ width: 50 }} />
        <div className="filterArea__container">
          <SelectList
            selectedValue={ilce}
            data={TownList?.map((item) => ({
              value: item.kayitNo,
              label: item.ilceAdi,
            }))}
            placeholder={
              il
                ? localization?.trading_points_ind_rep_rep_town_search_desc
                : localization?.trading_points_representative_first_pick_city
            }
            onChangeValue={(val: any) => {
              setIlce(val);
              handleFilterChange(il, val, search);
            }}
          />
        </div>
        <div style={{ width: 50 }} />
        <div className="filterArea__container">
          <SearchField
            searchQuery={search}
            setSearchQuery={(val: any) => {
              setSearch(val);
              handleFilterChange(il, ilce, val);
            }}
            placeHolder={localization?.trading_points_ind_rep_rep_search_desc}
            keyValue={"mask0_726_375representetives"}
          />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>
              {tab === 5
                ? localization?.trading_points_member_bus_rep_column_rep_no_desc
                : localization?.trading_points_ind_rep_rep_column_rep_no_desc}
            </th>
            <th>{localization?.trading_points_ind_rep_rep_column_rep_desc}</th>
            <th>{localization?.trading_points_ind_rep_rep_column_town_desc}</th>
            <th>
              {localization?.trading_points_ind_rep_rep_column_address_desc}
            </th>
            <th>
              {localization?.trading_points_ind_rep_rep_column_location_desc}
            </th>
          </tr>
        </thead>
        <tbody>
          {currentTableData.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item.MerchantNumber}</td>
              <td>{item.MerchantName}</td>
              <td>{item.Town}</td>
              <td>{item.Address}</td>
              <td>
                <LocationBlue
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      `https://www.google.com/maps?q=${item.Latitude},${item.Longitude}`,
                      "_blank"
                    )
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <br />
      {data?.code && (
        <div style={{ maxWidth: "fit-content", marginInline: "auto" }}>
          {localization?.trading_points_all_representatives_500_error}
        </div>
      )}
      {filteredData.length === 0 && !data?.code && (
        <div style={{ maxWidth: "fit-content", marginInline: "auto" }}>
          {localization?.trading_points_all_representatives_not_found_data}
        </div>
      )}
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={filteredData.length}
        pageSize={PageSize}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default App;
